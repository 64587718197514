import React, { useState } from 'react';
import { Box,Typography,Button } from '@mui/material';
import DebtResolution from './assets/DebtResolution.png'

const DebtResolutionPage = ({ updateServiceHeight }) => {
    const [expanded, setExpanded] = useState(false);

  const handleReadMore = () => {
    setExpanded(true);
    updateServiceHeight(900);
  };

  const handleReadLess = () => {
    setExpanded(false);
    updateServiceHeight(500);
  };
  return (

    <Box  marginTop='10px' width={'100%'} height={expanded ? 950 : 500} >
      <Box height={400} display="flex" alignItems="center">
      <Box p={1} marginRight={'1%'} marginLeft={'10%'}  marginTop={5}>
        <img src={DebtResolution} alt="Debt Resolution" />
      </Box>
      <Box p={1} marginTop={'-1%'} >
        <Typography variant="body1"  sx={{ color: 'black', font:'Roboto Condensed' ,width:'80%' }}>
          Our debt resolution services encompass the reorganization of asset-liability mismatches, alignment of debt servicing obligations with operational cashflows, and one-time settlements with lenders holding distressed debt(Non-Performing Assets or NPAs). Central to our resolution approach are considerations of business realities, stakeholder interests, and the business's long-term viability based on sustainable debt levels. Our profound understanding of these processes and adept articulation of pivotal issues have consistently yielded 'win-win' outcomes in challenging scenarios.
        </Typography>
        {!expanded && (
          <Button onClick={handleReadMore} color="primary" sx={{ width:'80%', height: 45, color:'#63968D',fontWeight:'bold', bgcolor: '#D4EAE6',  marginTop:'3%'}}>
            Read More
          </Button>
        )}
      </Box>
      </Box>
      {expanded && (
      <Box marginLeft={'10%'} width={'80%'}>
        <Typography variant="body1"  sx={{ color: 'black', font:'Roboto Condensed' , marginTop: '1%' }}>
          In today's dynamic business landscape, enterprises confront an increasingly complex array of challenges, spanning rapid technological advancements, intensified competition, and global economic volatility. This struggle with change, particularly evident in situations of over-leverage, can precipitate crisis environments characterized by operational and financial difficulties, often necessitating restructuring interventions. Leveraging our team's extensive experience, we provide guidance across all stages of financial restructurings, encompassing covenant resets, fresh capital injections, and one-time settlements.
        </Typography>
        <Typography variant="h4" component="div" sx={{ color: 'black', font:'Roboto Condensed', fontWeight: 'bold', marginTop: '2%' }}>
         WHAT WE OFFER
        </Typography>
        <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed',marginTop: '2%' }}>
         * Debt Restructuring
        </Typography>
        <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed', marginTop: '1px' }}>
         * One-Time Settlement
        </Typography>
        <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed',marginTop: '2%' }}>
        We assist clients in establishing timely and transparent mechanisms for restructuring corporate debts, which may involve extending repayment periods, reducing total indebtedness, or exchanging debt for equity in the debtor company.
        </Typography>
        <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed', marginTop: '2%' }}>
        Furthermore, we offer assistance with One-Time Settlements (OTS), providing troubled borrowers with an opportunity to resolve existing debt with lenders, thus averting the adversities of recovery proceedings and invocation of personal guarantees. Our interventions have effectively restored businesses to sustainable debt levels, safeguarding assets from deterioration.
        </Typography>
        <Button onClick={handleReadLess} color="primary" sx={{ width: '60%', height: 45, color:'#63968D',fontWeight:'bold', bgcolor: '#D4EAE6' , marginLeft:'20%', marginTop:'2%' }}>
          Read Less
        </Button>
      </Box>
    )}
  </Box>
  )
}

export default DebtResolutionPage