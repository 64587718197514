import React, { useState } from 'react';
import { Box,  Typography,Button } from '@mui/material';
import DebtResolution from './assets/EqulityAdvisory.png'

const EquityAdvisoryPage = ({ updateServiceHeight }) => {
    const [expanded, setExpanded] = useState(false);

    const handleReadMore = () => {
      setExpanded(true);
      updateServiceHeight(680);
    };
  
    const handleReadLess = () => {
      setExpanded(false);
      updateServiceHeight(500);
    };
    return (
  
      <Box  marginTop='10px' width={'100%'} height={expanded ? 680 : 500} >
      <Box height={400} display="flex" alignItems="center">
        {/* Image on the left */}
        <Box p={1} marginRight={'1%'} marginLeft={'10%'}  marginTop={5}>
          <img src={DebtResolution} alt="Debt Resolution" />
        </Box>
  
        {/* Typography on the right */}
        <Box p={1} marginTop={'-1%'} >
          <Typography variant="body1" component="div" sx={{ color: 'black',  font:'Roboto Condensed' ,width:'80%' , marginTop: '10%' }}>
          Our equity funding solutions enable clients to raise capital across different stages of the growth cycle. Many corporations seek capital to fuel their expansion without escalating their debt load. EFS serves as a strategic advisor, assisting companies in raising funds through private placements of their shares.
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black',  font:'Roboto Condensed' ,width:'80%' , marginTop: '3%' }}>
          We provide entrepreneurs and small and medium businesses with top quality transaction advice and insights
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black',  font:'Roboto Condensed' ,width:'80%' , marginTop: '0%' }}>
          We identify the most appropriate capital raising alternatives for our clients, and manage the entire equity capital raising process
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black',  font:'Roboto Condensed' ,width:'80%' , marginTop: '0%' }}>
          Our network with PE Funds and a good understanding of their needs enables us to structure, negotiate and execute transactions creating a win-win situation for both clients and investors.
          </Typography>
          {!expanded && (
           <Button onClick={handleReadMore} color="primary" sx={{ width: '80%', height: 35,  color:'#63968D',fontWeight:'bold',bgcolor: '#D4EAE6',  marginTop:'5%'}}>
           Read More
         </Button>
          )}
        </Box>
      </Box>
      
      {expanded && (
        <Box marginLeft={'12%'} width={'80%'}>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed', fontWeight: 'bold', marginTop: '20px' }}>
          Our Equity Funding Services Include
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed', marginTop: '20px' }}>
           * Private Equity
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed', marginTop: '1px' }}>
           * Pre-IPO Placements
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed', marginTop: '1px' }}>
           * Venture Funding
          </Typography>
          <Button onClick={handleReadLess} color="primary" sx={{ width: '80%', height: 35, color:'#63968D',fontWeight:'bold', bgcolor: '#D4EAE6' ,  marginLeft:'25%',marginTop:'3%' }}>
            Read Less
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default EquityAdvisoryPage