import React, { useState } from 'react';
import { Box,  Typography,Button } from '@mui/material';
import DebtResolution from './assets/Madvisory1.png'

const MAndAAdvisoryPage = ({ updateServiceHeight }) => {
    const [expanded, setExpanded] = useState(false);

    const handleReadMore = () => {
      setExpanded(true);
      updateServiceHeight(700);
    };
  
    const handleReadLess = () => {
      setExpanded(false);
      updateServiceHeight(500);
    };
    return (
  
      <Box  marginTop='10px' width={'100%'} height={expanded ? 700 : 400} >
      <Box height={400} display="flex" alignItems="center">
        {/* Image on the left */}
        <Box p={1} marginRight={'1%'} marginLeft={'10%'}  marginTop={5}>
          <img src={DebtResolution} alt="Debt Resolution" />
        </Box>
  
        {/* Typography on the right */}
        <Box p={1} marginTop={'-1%'} >
          <Typography variant="body1" component="div" sx={{ color: 'black',  font:'Roboto Condensed' ,width:'80%' }}>
          EFS offers comprehensive expertise across the entire lifecycle of Mergers and Acquisitions transactions, starting from the initial screening of target acquisitions and conducting due diligence reviews, all the way to providing post-acquisition integration support, ensuring the realization of maximum value.
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' ,width:'80%', marginTop: '3%' }}>
          Offering unmatched buy-side and sell-side advisory services within the Indian market for both Indian and global corporates, we leverage a comprehensive relationship network to ensure seamless access to counterparties.
          </Typography>
          {!expanded && (
            <Button onClick={handleReadMore} color="primary" sx={{ width: '80%', height: 35,  color:'#63968D',fontWeight:'bold',bgcolor: '#D4EAE6',  marginTop:'5%'}}>
              Read More
            </Button>
          )}
        </Box>
      </Box>
      
      {expanded && (
         <Box marginLeft={'10%'} width={'80%'}>
         
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , fontWeight: 'bold', marginTop: '20px' }}>
          Buy-Side Advisory
          </Typography>
        <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , marginTop: '5px' }}>
        We aid our clients by evaluating the strategic alignment of a business, commencing with a thorough analysis of all transaction aspects, projecting synergies, managing the process, assisting in negotiations, conducting financial modeling, and evaluating transaction implications.
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , fontWeight: 'bold', marginTop: '20px' }}>
          Sell-Side Advisory
          </Typography>
        <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , marginTop: '5px' }}>
        Serving as a crucial link in the strategic sale process, we guide our clients through transactions, handle negotiations on their behalf, and provide impartial advice on bid quality.
          </Typography>
          <Button onClick={handleReadLess} color="primary" sx={{ width: '60%', height: 35, color:'#63968D',fontWeight:'bold', bgcolor: '#D4EAE6' , marginLeft:'25%', marginTop:'1%' }}>
            Read Less
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default MAndAAdvisoryPage