// GlobalStyles.js
import { GlobalStyles as MuiGlobalStyles } from '@mui/material';
import { createTheme, styled } from '@mui/system';

const theme = createTheme();

export const GlobalStyles = styled(MuiGlobalStyles)`
  body {
    font-family: 'Roboto Condensed', sans-serif;
  }
`;
