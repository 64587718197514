import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography,Button, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Sample images
import Image1 from './assets/Image1.png';
import Image2 from './assets/Image2.png';
import Image3 from './assets/Image3.png';
import Image4 from './assets/image4.png';
import DebtRecoveryIcon from './assets/Debt - Recovery.png';
import DebtSyndicationIcon from './assets/Debt Syndication.png'
import RatingAdvisoryIcon from './assets/RatingAdvisory.png'
import ParallaxComponent from './ParallaxComponent';

const Home = ({ setValue }) => {
  const theme = useTheme();
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    width:'100%',
  };

  return (
    <Box  marginTop='120px'  >
        <Box >
          {/* Slider */}
          <Slider {...sliderSettings}>
            <div >
              <img src={Image1} alt="Image 1"  width={'100%'} />
            </div>
            <div>
              <img src={Image2} alt="Image 2"  width={'100%'} />
            </div>
            <div>
              <img src={Image3} alt="Image 3"  width={'100%'} />
            </div>
          </Slider>
        </Box>
        <Box bgcolor="#E3E3E3" height={200} p={10} mt={-1} >
            <Typography  style={{ marginLeft:'8%'}}  font="Roboto" fontSize={60} fontWeight={'bold'} color={'#006357'}>About Us</Typography>
            <Typography  style={{ marginLeft:'8%'}}  font="Roboto" fontWeight={'bold'} color={'#000000'}>
            For Business Strategies that go beyond numbers augmented with Financial Advisory that create value in all aspects to business and society, partner with 
            Elements Financial Solutions Private Limited (EFS).Established in year 2013, we are a Global Financial and Business Advisory solutions firm.
            We collaborate with our clients to spin challenges into opportunities and usher positive change on local, national and international level.
          </Typography>
        </Box>
         <Box height={800} style={{ backgroundColor: 'rgba(0, 0, 0, 1)', backgroundImage: `url(${Image4})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
         <Typography  style={{ marginLeft:'12%', height:'5%' }}  font="Roboto" fontSize={62} fontWeight={'bold'} color={'#FFFFFF'}></Typography>
           <Typography  style={{ marginLeft:'12%', }}  font="Roboto" fontSize={62} fontWeight={'bold'} color={'#FFFFFF'}>Why Elements Financial Solutions (EFS)</Typography>
            <Typography  style={{ marginLeft:'12%' ,width:'80%',marginTop:'1%'}} fontWeight={'bold'} font="Roboto" fontSize={20}  color={'#FFFFFF'}>
            Choosing Elements Financial Solutions offers numerous benefits for businesses seeking comprehensive financial expertise and strategic support. Here’s why you should consider partnering with us: 
          </Typography>
          <Typography   style={{ marginLeft:'12%' ,width:'80%',marginTop:'1%'}} fontWeight={'bold'} font="Roboto" fontSize={20}  color={'#FFFFFF'}>
           1.Expert Guidance: Our team comprises seasoned professionals with extensive experience in financial advisory, ensuring you receive knowledgeable and effective solutions tailored to your unique needs. 
          </Typography>
          <Typography   style={{ marginLeft:'12%' ,width:'80%',marginTop:'1%'}} fontWeight={'bold'} font="Roboto" fontSize={20}  color={'#FFFFFF'}>
           2.Comprehensive Services: From credit rating advisory to structured and private credit financing, we provide a full suite of services that cover every aspect of corporate finance, helping businesses optimize their financial operations and maximize growth opportunities.
          </Typography>
          <Typography   style={{ marginLeft:'12%' ,width:'80%',marginTop:'1%'}} fontWeight={'bold'}  font="Roboto" fontSize={20}  color={'#FFFFFF'}>
           3.Customized Solutions: We understand that each client has unique challenges and goals. Our approach is to craft personalized strategies that align with your specific business objectives and market conditions.
          </Typography>
          <Typography   style={{ marginLeft:'12%' ,width:'80%',marginTop:'1%'}} fontWeight={'bold'} font="Roboto" fontSize={20}  color={'#FFFFFF'}>
            4.Strategic Partnerships: With strong relationships in the banking and finance industry, we facilitate enhanced access to capital, better financing terms, and strategic partnerships that can propel your business forward.
          </Typography>
          <Typography   style={{ marginLeft:'12%' ,width:'80%',marginTop:'1%'}}  fontWeight={'bold'} font="Roboto" fontSize={20} color={'#FFFFFF'}>
            5.Proven Track Record: Backed by years of success, our firm has a proven track record of delivering results, from improving credit ratings to securing substantial funding for complex projects
          </Typography>
          <Typography   style={{ marginLeft:'12%' ,width:'80%',marginTop:'1%'}} fontWeight={'bold'} font="Roboto" fontSize={20}  color={'#FFFFFF'}>
            6.Commitment to Client Success: At Elements Financial Solutions, your success is our priority. We are dedicated to providing the support and resources necessary to navigate financial complexities and achieve lasting success.
          </Typography>
          <Typography  style={{ marginLeft:'12%' ,width:'80%',marginTop:'1%'}} fontWeight={'bold'} font="Roboto" fontSize={20}  color={'#FFFFFF'}>
          Choosing us means partnering with a leader in financial advisory committed to delivering excellence and driving significant value for your business.
          </Typography>
        </Box>
      
        

<Box bgcolor="#E3E3E3" height={'100%'} p={'10%'}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Box
            bgcolor="#FFFFFF"
            height={'100%'}
            borderRadius={8}
            p={2}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <div>
              <img
                src={DebtRecoveryIcon}
                alt="Debt Resolution"
                style={{
                 marginTop:'-17%',width:'30%' ,height:'28%',
                 marginLeft:'35%',
                  objectFit: 'cover',
                  borderRadius: '8px 8px 0 0' // Rounded corners on top only
                }}
              />
              <Typography
                variant="h4"
                style={{
                  color: '#000000',
                  marginTop: '1rem',
                  textAlign: 'center',
                  font: 'Roboto Condensed',
                  fontWeight:'bold'
                }}
              >
                Debt Resolution
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: '#000000',
                  marginTop: '0.5rem',
                  marginRight :'0.2rem',
                  marginLeft :'0.5rem',
                  textAlign: 'left',
                  font: 'Roboto Condensed',
                  fontWeight: 'bold'
                }}
              >
                Our debt resolution services encompass the reorganization of asset-liability mismatches, alignment of debt servicing obligations with operational cash flows, and one-time settlements with lenders holding distressed debt (Non-Performing Assets or NPAs).
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: '#000000',
                  marginTop: '0.5rem',
                  marginRight :'0.2rem',
                  marginLeft :'0.5rem',
                  textAlign: 'left',
                  font: 'Roboto Condensed'
                }}
              >
                Central to our resolution approach are considerations of business realities, stakeholder interests, and the business's long-term viability based on sustainable debt levels. Our profound understanding of these processes and adept articulation of pivotal issues have consistently yielded 'win-win' outcomes in challenging scenarios.
              </Typography>
            </div>
            <Button
              component={Link}
              to="/service"
              variant="outlined"
              style={{
                marginTop: 'auto',
                width: '100%',
                borderRadius: '999px',
                color: '#666666',
                height: '10%',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#006956';
                e.target.style.color = '#ffffff';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'transparent';
                e.target.style.color = '#666666';
              }}
            >
              Know More
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            bgcolor="#FFFFFF"
            height={'100%'}
            borderRadius={8}
            p={2}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <div>
              <img
                src={DebtSyndicationIcon}
                alt="Debt Syndication"
                style={{
                  marginTop:'-17%',width:'30%' ,height:'31%',
                  marginLeft:'35%',
                  objectFit: 'cover',
                  borderRadius: '8px 8px 0 0' // Rounded corners on top only
                }}
              />
              <Typography
                variant="h4"
                style={{
                  color: '#000000',
                  marginTop: '1rem',
                  textAlign: 'center',
                  font: 'Roboto Condensed',
                   fontWeight: 'bold' 
                }}
              >
                Debt Syndication
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: '#000000',
                  marginTop: '0.5rem',
                  marginRight :'0.2rem',
                  marginLeft :'0.5rem',
                  textAlign: 'left',
                  font: 'Roboto Condensed',
                  fontWeight: 'bold'
                }}
              >
                “Apart from providing immunity from market volatility, syndicated loans offer low transaction costs and saves companies the time and effort of negotiating with each bank individually”
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: '#000000',
                  marginTop: '0.5rem',
                  marginRight :'0.2rem',
                  marginLeft :'0.5rem',
                  textAlign: 'left',
                  font: 'Roboto Condensed'
                }}
              >
                Our forte lies in providing clear analysis of the available debt funding options based on clients requirement, advising them on most suitable option while providing end to end support. Our loan syndication team is developed uniquely to bridge the gap between the promoters and lenders through its understanding and experience in this area.
              </Typography>
            </div>
            <Button
              component={Link}
              to="/service"
              variant="outlined"
              style={{
                marginTop: 'auto',
                width: '100%',
                borderRadius: '999px',
                color: '#666666',
                height: '10%',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#006956';
                e.target.style.color = '#ffffff';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'transparent';
                e.target.style.color = '#666666';
              }}
            >
              Know More
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            bgcolor="#FFFFFF"
            height={'100%'}
            borderRadius={8}
            p={2}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <div>
              <img
                src={RatingAdvisoryIcon}
                alt="Rating Advisory"
                style={{
                  marginTop:'-17%',width:'30%' ,height:'34%',
                  marginLeft:'35%',
                  objectFit: 'cover',
                  borderRadius: '8px 8px 0 0' // Rounded corners on top only
                }}
              />
              <Typography
                variant="h4"
                style={{
                  color: '#000000',
                  marginTop: '1rem',
                  textAlign: 'center',
                  font: 'Roboto Condensed',
                  fontWeight: 'bold'
                }}
              >
                Rating Advisory
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: '#000000',
                  marginTop: '0.5rem',
                  marginRight :'0.2rem',
                  marginLeft :'0.5rem',
                  textAlign: 'left',
                  font: 'Roboto Condensed',
                  fontWeight: 'bold'
                }}
              >
                EFS serve as a vital intermediary connecting corporates seeking ratings with domestic and international credit rating agencies.
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: '#000000',
                  marginTop: '0.5rem',
                  textAlign: 'left',
                  marginRight :'0.2rem',
                  marginLeft :'0.5rem',
                  fontFamily: 'Roboto Condensed'
                }}
              >
                Leveraging the EFS team's extensive experience, we add significant value by facilitating desired rating upgrades or preventing downgrades through strategic refinement. Our profound grasp of rating methodologies and key credit rating determinants empowers more informed business decisions. With a comprehensive understanding of credit issues and rating triggers, our team ensures effective risk management.
              </Typography>
            </div>
            <Button
              component={Link}
              to="/service"
              variant="outlined"
              style={{
                marginTop: 'auto',
                width: '100%',
                borderRadius: '999px',
                color: '#666666',
                height: '10%',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#006956';
                e.target.style.color = '#ffffff';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'transparent';
                e.target.style.color = '#666666';
              }}
            >
              Know More
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>


   </Box>
   
  );
};

export default Home;
