import React, { useState } from 'react';

import Contactimg from './assets/Contact.png'
import { Box, Container,TextField, Grid, Typography,Button } from '@mui/material';
import bigmap from './assets/Map1.png'
import locatioicon from './assets/Location.png'
import emailIcon from './assets/Email.png'
import CallIcon from './assets/Call.png'
import Map from './Map';
const Contact = () => {

  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you can handle form submission, e.g., send the form data to your backend server
    console.log('Form submitted:', { name, contact, email, subject, message });
    // You can add your logic here to send the form data to the backend or perform any other action
  };

  return (
    <Box  marginTop='120px'  >
      <Box height={360}  style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}>
         <img src={Contactimg} alt="Services1" width={'100%'}  height={'100%'} /> 
     </Box>
 
    <Box sx={{ width: '60%' }} marginTop='120px'   marginLeft={'20%'} >
      <Box sx={{ textAlign: 'center', my: 4  }} >
        <Typography variant="h4" gutterBottom fontWeight={'bold'}  style={{font:'Roboto Condensed'}}  marginTop={'3%'} >
          If you need any assistance please    <span style={{ color: '#006956' }}>contact us!</span>
        </Typography>
      </Box>
    </Box>

    <Box  height={120}  sx={{ width: '80%' }}   marginLeft={'10%'} >
                  <Grid container spacing={3}>
                  <Grid item xs={16} md={4}  marginleft= '200px'>
                      <Box display={'flex'}>
                      <Box bgcolor="#006956" width={25} height={120} borderRadius='16px 0 0 16px' p={1} display="flex" alignItems="center" justifyContent="center">
                        <img src={locatioicon} alt="Location Icon" />
                      </Box>
                        <Box bgcolor="#EDEDED" height={120}  p={1}  >
                        <Typography  variant="h5" style={{ color:'#006956', fontWeight:'bold'}}>Head office address</Typography>
                        <Typography  variant="body1" style={{ color:'#666666',marginTop:'1%'}}>Elements Financial Solutions Private Limited 805, 2nd Floor, A Block, E- Tradelink, Kamala Mills, Lower Parel, Mumbai - 400013</Typography>
                                    
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={16} md={4}>
                  <Box display={'flex'}>
                      <Box bgcolor="#006956" width={25} height={120} borderRadius='16px 0 0 16px' p={1} display="flex" alignItems="center" justifyContent="center">
                        <img src={emailIcon} alt="Location Icon" />
                      </Box>
                  <Box bgcolor="#EDEDED" width={400} height={120}  p={1}  >
                  
                   <Typography  variant="h5" style={{ color:'#006956', fontWeight:'bold', padding:'1%'}}>Mail for information</Typography>
                   <Typography  variant="body1" style={{ color:'#666666' , paddingLeft:'1%'}}>siddesh@elementsfs.com</Typography>
                   <Typography  variant="body1" style={{ color:'#666666', paddingLeft:'1%'}}>info@elementsfs.com</Typography>
                   
                              
                 </Box>
                 </Box>
                  </Grid>
                  <Grid item xs={16} md={4}>
                  <Box display={'flex'}>
                      <Box bgcolor="#006956" width={25} height={120} borderRadius='16px 0 0 16px' p={1} display="flex" alignItems="center" justifyContent="center">
                        <img src={CallIcon} alt="Location Icon" />
                      </Box>
                  <Box bgcolor="#EDEDED" width={400} height={120}  p={1}  >
                  
                   <Typography  variant="h5" style={{ color:'#006956', fontWeight:'bold'}}>Call for assistance</Typography>
                   <Typography  variant="body1" style={{ color:'#666666',marginTop:'1%'}}>+91 9920 820 795</Typography>
                              
                 </Box>
                 </Box>
                  </Grid>
                </Grid>
    </Box>

    <Box height={400}  sx={{ width: '80%' }}  mt={'8%'}  marginLeft={'10%'}  >
    {/* <Map  width={'100%'} height={'100%'} style={{ objectFit: 'cover' }}/> */}
      <img src={bigmap}  width={'100%'} height={'100%'} style={{ objectFit: 'cover' }} />
    </Box>

    <Box height={550}  sx={{ width: '80%' }}  mt={'8%'}   marginLeft={'10%'} >
    <Box sx={{ textAlign: 'center', my: 4 }}>
      <Typography variant="h4" gutterBottom fontWeight={'bold'} marginTop={'3%'} style={{ color: '#006956',  borderBottom: '3px solid #006956' }}>
        Ask Query
      </Typography>
    </Box>
    <form onSubmit={handleSubmit}>
      <Box sx={{ display: 'flex', flexDirection: 'row', maxWidth: '1200px', mb: 6 }}>
        <TextField
          fullWidth
          label="Your Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          sx={{ mr: 2 ,width:'400%' }}
        />
        <TextField
          fullWidth
          label="Contact"
          variant="outlined"
          value={contact}
          onChange={(e) => setContact(e.target.value)}
          required
          sx={{ mr: 2 ,width:'400%' }}
        />
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          sx={{width:'400%' }}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          label="Subject"
          variant="outlined"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          label="Message"
          multiline
          rows={4}
          variant="outlined"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
      </Box>
      <Button type="submit" variant="contained" color="primary"  sx={{backgroundColor:"#006956" ,marginLeft:'34%' ,width:388 , height:58}}>
        Send
      </Button>
    </form>
    </Box>
  </Box>
  )
}

export default Contact
