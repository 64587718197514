import React, { useState } from 'react';
import { Box, Container, Grid, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import './CurvedHeader.css';
import EmailIcon from './assets/Email.svg';
import MobileIcon from './assets/Mobile.svg';
import linkedinIcon from './assets/linkedin.svg';
import FacebookIcon from './assets/Facebook.svg';
import twitterIcon from './assets/Twitter-2023.svg';
import logo from './assets/Logo.svg';
import Rectangleicon from './assets/Rectangle.svg';
import './Header.css';

const Header = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header style={{ position: 'fixed', width: '100%', zIndex: '1000', marginTop: '-124px' }}>
      {/* Small Header */}
      <Box bgcolor="#000000" height={45} display="flex" justifyContent="space-between" alignItems="center"
        sx={{
          '@media (max-width: 600px)': {
            height: '100%',
            width: '48%',
         
          },
          '@media (min-width: 601px) and (max-width: 1024px)': {
            height: '120%',
            width: '80%',
         
          },
          '@media (min-width: 1024px) and (max-width: 1920px)': {
            height: '200%',
            width: '100%',
         
          },
          '@media (min-width: 1920px)': {
            height: '250%',
            width: '120%',
         
          }
        }} >
        <Grid container justifyContent="space-between" alignItems="center" marginTop={'9px'}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" component="div" sx={{ color: 'white', margin: 'auto', textAlign: 'center' }}>
              <img src={twitterIcon} alt="twitter" style={{ marginRight: '20px' }} />
              <img src={FacebookIcon} alt="Facebook" style={{ marginRight: '20px' }} />
              <a href="https://www.linkedin.com/company/elements-financial-solutions-efs/" target="_blank" rel="noopener noreferrer">
                <img src={linkedinIcon} alt="linkedin" style={{ marginRight: '20px' }} />
              </a>
            </Typography>
          </Grid>

          <Typography variant="body1" component="div" sx={{ display: 'flex', color: 'white', fontSize: { xs: '8px', sm: '11px' } }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <img src={EmailIcon} alt="Email" style={{ marginRight: '10px' }} />
              info@elementsfs.com
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginLeft: '10px' }}>
              <img src={MobileIcon} alt="Email" style={{ marginRight: '10px' }} />
              +91 9920 820 795
            </div>
          </Typography>

          <Typography variant="body1" component="div" sx={{ color: 'white', fontSize: '18px' }}></Typography>
        </Grid>
      </Box>
      <Box 
        sx={{
          height: '100%',
          position: 'absolute',
          top: '100%',
          bgcolor: '#00362C',
          backgroundImage: `url(${Rectangleicon})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          '@media (max-width: 600px)': {
            height: '50%',
            width: '48%',
         
          },
          '@media (min-width: 601px) and (max-width: 1024px)': {
            height: '120%',
            width: '80%',
         
          },
          '@media (min-width: 1024px) and (max-width: 1920px)': {
            height: '260%',
            width: '100%',
         
          },
          '@media (min-width: 1920px)': {
            height: '310%',
            width: '120%',
         
          },
        }}
      >
        <Container style={{ marginTop: '12px', marginLeft: '9%' }}>
          <Toolbar>
           
            {isMobile ? (
              <>
               <img src={logo} alt="logo" style={{ marginRight: '48%',  height:'10%' ,width:'20%'}} />

                <IconButton color="inherit" onClick={handleToggleMenu}>
                  <MenuIcon />
                </IconButton>
                <Drawer anchor="right" open={menuOpen} onClose={handleToggleMenu}>
                  <IconButton onClick={handleToggleMenu}>
                    <CloseIcon />
                  </IconButton>
                  <List>
                    <ListItem button component={Link} to="/" onClick={handleToggleMenu}>
                      <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button component={Link} to="/about" onClick={handleToggleMenu}>
                      <ListItemText primary="About" />
                    </ListItem>
                    <ListItem button component={Link} to="/Service" onClick={handleToggleMenu}>
                      <ListItemText primary="Services" />
                    </ListItem>
                    <ListItem button component={Link} to="/transactions" onClick={handleToggleMenu}>
                      <ListItemText primary="Transactions" />
                    </ListItem>
                    <ListItem button component={Link} to="/Contact" onClick={handleToggleMenu}>
                      <ListItemText primary="Contact" />
                    </ListItem>
                  </List>
                </Drawer>
              </>
            ) : (
              <>
               <img src={logo} alt="logo" style={{ marginRight: '48%',  }} />

                <Button
                  component={Link}
                  to="/"
                  color="inherit"
                  style={{
                 
                    color: 'white',
                    marginRight: '10px',
                    backgroundColor: location.pathname === '/' ? '#006956' : 'transparent',
                    borderRadius: '18px',
                    border: '2px solid transparent',
                    transition: 'border-color 0.3s',
                  }}
                  sx={{
                    '&:hover': {
                      borderColor: 'white',
                    },
                  }}
                >
                  Home
                </Button>
                <Button
                  component={Link}
                  to="/about"
                  color="inherit"
                  style={{
                    color: 'white',
                    marginRight: '10px',
                    backgroundColor: location.pathname === '/about' ? '#006956' : 'transparent',
                    borderRadius: '18px',
                    padding: '6px 30px',  // Add padding to ensure the background color covers the button
                    boxSizing: 'border-box',  // Ensure padding is included in the button size
                  }}
                >
                  About
                </Button>
                <Button
                  component={Link}
                  to="/Service"
                  color="inherit"
                  style={{
                    color: 'white',
                     width: '100%',
                    marginRight: '10px',
                    backgroundColor: location.pathname === '/Service' ? '#006956' : 'transparent',
                    borderRadius: '18px',
                    padding: '8px 55px',  // Add padding to ensure the background color covers the button
                    boxSizing: 'border-box',  // Ensure padding is included in the button size
                  }}
                >
                  Services
                </Button>
                <Button
                  component={Link}
                  to="/transactions"
                  color="inherit"
                  
                  style={{
                    color: 'white',
                    width: '100%',
                    marginRight: '20px',
                    backgroundColor: location.pathname === '/transactions' ? '#006956' : 'transparent',
                    borderRadius: '18px',
                    padding: '8px 65px',  // Add padding to ensure the background color covers the button
                    boxSizing: 'border-box',  // Ensure padding is included in the button size
                  }}
                >
                  Transactions
                </Button>
                <Button
                    component={Link}
                    to="/Contact"
                    color="inherit"
                    style={{
                      color: 'white',
                      width: '100%',
                      marginRight: '10px',
                      backgroundColor: location.pathname === '/Contact' ? '#006956' : 'transparent',
                      borderRadius: '18px',
                      padding: '8px 40px',  // Add padding to ensure the background color covers the button
                      boxSizing: 'border-box',  // Ensure padding is included in the button size
                    }}
                  >
                  Contact
                </Button>
              </>
            )}
          </Toolbar>
        </Container>
      </Box>
    </header>
  );
};

export default Header;
