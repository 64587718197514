import { Box, Container, Grid, Typography,Button ,Card, CardContent, CardMedia,IconButton} from '@mui/material';
import Transactionsimg from './assets/Transactions.png'
import teamMembers from './Data/Transactions.json';

const Transactions = () => {
  
const TeamCard = ({ index ,Company,Amount,Sector,Deal }) => (
<Card sx={{ display: 'flex', flexDirection: 'column', width: 400, marginTop: index === 1 ? 1 : -5,marginBottom: index === 12 ? 2 : 0,  borderRadius: '18px', backgroundColor: '#E9E9E9' }}>
  <Box sx={{ display: 'flex', flexDirection: 'row', p: 0 }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, margin: 'auto', backgroundColor: '#FFFFFF', }}>
     
      <Card sx={{  width: '97%', borderRadius: '10px', backgroundColor: '#CBEAE5', marginLeft: '1%',marginRight:'1%',marginTop:'2%'  }}>
      <Typography gutterBottom  component="div" sx={{ color: '#000000',font: 'Roboto', fontWeight: 'bold', fontSize:'14px',marginTop: '8px'  , marginBottom: '8px'  ,marginLeft:3}}>
        {Company}
      </Typography>
      </Card>
      <Typography variant="body1" color="text.primary" sx={{ ml: 3, mt: 1  ,font: 'Roboto',fontWeight: 'regular'}}>
         * {Amount}
        </Typography>
        <Typography variant="body1" color="text.primary" sx={{ ml: 3 ,font: 'Roboto',fontWeight: 'regular' }}>
        *  {Sector}
        </Typography>
        <Typography variant="body1" color="text.primary" sx={{ ml: 3 ,mb: 1  ,font: 'Roboto',fontWeight: 'regular'}}>
        *  {Deal}
        </Typography>
    </Box>
  </Box>
</Card>
);


  return (
    <Box  marginTop='120px' >
    <Box height={360}  style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}>
       
        <img src={Transactionsimg}  width={'100%'}  height={'100%'} /> 
      
 </Box>
 <Box bgcolor="#F1F1F1" pt={7} pb={5}>
      <Container>
        <Grid container spacing={12} justifyContent="center">
          {teamMembers.map((member, index) => (
            <Grid item xs={8} md={4} key={index}>
              <TeamCard {...member} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  </Box>
  );
};


export default Transactions
