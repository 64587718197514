import React, { useState } from 'react';
import { Box,  Typography,Button } from '@mui/material';
import DebtResolution from './assets/Debt Syndication1.png'

const DebtSyndicationPage = ({ updateServiceHeight }) => {
    const [expanded, setExpanded] = useState(false);

    const handleReadMore = () => {
      setExpanded(true);
      updateServiceHeight(1150);
    };
  
    const handleReadLess = () => {
      setExpanded(false);
      updateServiceHeight(500);
    };
    return (
  
      <Box marginTop='10px'  width={'100%'}  height={expanded ? 1300 : 600} >
      <Box height={400} display="flex" alignItems="center">
       
        <Box p={1}   marginLeft={'10%'} marginTop={5}>
          <img src={DebtResolution} alt="Debt Resolution" />
        </Box>
  
      
        <Box p={1} marginTop={'1%'} >
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' ,width:'80%' }}>
          EFS engages with a diverse range of clients, including Corporates, SMEs, and Mid-Corporate Clients, with the aim of delivering structured financing solutions tailored to meet their capital and growth financing requirements. Serving as both an advisor and arranger, we raise funds through various debt instruments, assisting clients in fortifying their balance sheets with customized capital structure alternatives crafted for optimal profitability.
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' ,width:'80%', marginTop: '3%' }}>
          EFS holds a prominent position in the debt structuring and distribution landscape in India, boasting strong partnerships with AIFs, Family Offices, Banks (both in the public and private sectors), Financial Institutions, NBFCs, Mutual Funds, and Insurance Companies. We cultivate enduring relationships with our clients by offering timely and tailored solutions to help them achieve their financial objectives.
          </Typography>
          {!expanded && (
            <Button onClick={handleReadMore} color="primary" sx={{ width:'80%', height: 45,  color:'#63968D',fontWeight:'bold',bgcolor: '#D4EAE6',  marginTop:'3%'}}>
              Read More
            </Button>
          )}
        </Box>
      </Box>
      
      {expanded && (
        <Box marginLeft={'10%'} width={'80%'} >
          <Typography variant="body1"  sx={{ color: 'black',  font:'Roboto Condensed' , marginTop: '1%' }}>
          Drawing on our institutional relationships and established position in the market, EFS serves as an arranger for debt syndication across various corporate entities in both private and public sectors. We take pride in our associations with leading corporations in the Indian business landscape and have successfully executed mandates for syndication of numerous term loans and working capital facilities.
          </Typography>
        
          <Typography variant="h4" component="div" sx={{ color: 'black', font:'Roboto Condensed' ,  fontWeight: 'bold', marginTop: '20px' }}>
           We offer the subsequent services to clients in raising debt
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' ,  marginTop: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>Project Finance:</span> Project finance is the long-term financing of commercial manufacturing or any other such projects based upon the projected cash flows of the project instead of the balance sheets of its sponsors.
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' ,  marginTop: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>Working Capital Finance: </span>A working capital loan is a loan that's taken to finance a company's every day operations. These finance to buy long-term assets or investments and are, instead, provide the finances that cover a company's short-term operational needs.
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' ,  marginTop: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>Structured Financing</span>  It is a complex form of financing, used for a large-scale fund infusion. It is beyond the scope of conventional tools like a loan or a bond. Borrowers with higher needs seek structured funding in the form of Collateralized Debt-obligations, Syndicated loans, and Mortgage-Backed Securities.
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' ,  marginTop: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>Acquisition Funding:</span> Acquisition financing is that the funding a corporation uses specifically to acquire another company. By acquiring another company, a company can increase the dimensions of its operations and enjoy the economies of scale achieved through the acquisition.
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' ,  marginTop: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>Promoter Funding:</span> A facility provided to promoters of well-managed companies to boost funds against their stake therein operating company. These funds are often utilized for various needs like financing for Acquisitions and take-over financing and business growth. These funding are short to medium-term in nature.
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' ,  marginTop: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>Mezzanine Funding:</span> In finance, mezzanine capital is any subordinated debt or preferred equity instrument that represents a claim on a company's assets which is senior only thereto of the common stock. It is often structured either as debt or preferred shares or another form of quasi-equity.
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' ,  marginTop: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>Overseas Funding:</span>Overseas funding refers to a fund that invests in companies outside the country of residence of the investor. These are often also called as international funds or foreign funds. Overseas funding is often through closed-end funds, exchange-traded funds or mutual funds,
          </Typography>
          
          <Button onClick={handleReadLess} color="primary" sx={{ width: '60%', height: 45, color:'#63968D',fontWeight:'bold', bgcolor: '#D4EAE6' , marginLeft:'25%', marginTop:'3%' }}>
            Read Less
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default DebtSyndicationPage