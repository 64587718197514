
import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';

import Services1 from './assets/Services1.png'

import DebtResolutionIcon from './assets/DebtResolution.svg';
import DebtSyndicationIcon from './assets/DebtSyndication.svg';
import RatingAdvisoryIcon from './assets/RatingAdvisory.svg';
import MAndAAdvisoryIcon from './assets/Madvisory.svg';
import EquityAdvisoryIcon from './assets/EqulityAdvisory.svg';


import DebtResolutionPage from './DebtResolution';
import DebtSyndicationPage from './DebtSyndication';
import RatingAdvisoryPage from './RatingAdvisory';
import MAndAAdvisoryPage from './MAndAAdvisory';
import EquityAdvisoryPage from './EquityAdvisory';

const Service = () => {
  const [value, setValue] = useState(0);
  const [serviceHeight, setServiceHeight] = useState(500);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setServiceHeight(500)
  };

  const subpages = [
    <DebtResolutionPage updateServiceHeight={setServiceHeight} />,
    <DebtSyndicationPage updateServiceHeight={setServiceHeight} />,
    <RatingAdvisoryPage updateServiceHeight={setServiceHeight} />,
    <MAndAAdvisoryPage updateServiceHeight={setServiceHeight} />,
    <EquityAdvisoryPage updateServiceHeight={setServiceHeight} />,
  ];
  return (
<Box  marginTop='120px' width={'100%'} >
    <Box height={360}  style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}>
       <img src={Services1} alt="Services1" width={'100%'}  height={'100%'}/> 
      
    </Box>
     <Box  marginTop='1px' height={'20%'} style={{  backgroundColor:"#D9D9D9"}} display="flex" justifyContent="center">
      <Box style={{ marginLeft:'-7%' , backgroundColor:"#D9D9D9"}}>
      <Tabs
    value={value}
    onChange={handleChange}
    indicatorColor="black"
>
    <Tab
        label={<>
        <img src={DebtResolutionIcon} alt="Debt Resolution" style={{ width: '50px', height: '24px', filter:value === 0 ?'invert(1)': 'invert(0)' }} />
            <span style={{ color: value === 0 ? 'white' : 'black' }}>Debt Resolution</span>
        </>}
        sx={{
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: value === 0 ? '#00362C' : 'transparent',
           
        }}
    />
    <Tab
        label={<>
            <img src={DebtSyndicationIcon} alt="Debt Syndication" style={{  width: '65px', height: '24px' ,filter:value === 1 ?'invert(1)': 'invert(0)'}} />
             <span style={{ color: value === 1 ? 'white' : 'black' }}>Debt Syndication</span>
        </>}
        sx={{ flexDirection: 'row', alignItems: 'center'   , backgroundColor: value === 1 ? '#00362C' : 'transparent'}}
    />
    <Tab
        label={<>
            <img src={RatingAdvisoryIcon} alt="Rating Advisory" style={{  width: '65px', height: '24px',filter:value === 2 ?'invert(1)': 'invert(0)' }} />
            <span style={{ color: value === 2 ? 'white' : 'black' }}> Rating Advisory</span>
        </>}
        sx={{ flexDirection: 'row', alignItems: 'center' , backgroundColor: value === 2 ? '#00362C' : 'transparent' }}
    />
    <Tab
        label={<>
            <img src={MAndAAdvisoryIcon} alt="M & A Advisory" style={{  width: '65px', height: '24px' ,filter:value === 3 ?'invert(1)': 'invert(0)'}} />
            <span style={{ color: value === 3 ? 'white' : 'black' }}>M & A Advisory</span>
           
        </>}
        sx={{ flexDirection: 'row', alignItems: 'center', backgroundColor: value === 3 ? '#00362C' : 'transparent' }}
    />
    <Tab
        label={<>
            <img src={EquityAdvisoryIcon} alt="Equity Advisory" style={{ width: '65px', height: '24px',filter:value === 4 ?'invert(1)': 'invert(0)' }} />
            <span style={{ color: value === 4 ? 'white' : 'black' }}>Equity Advisory</span>
            
        </>}
        sx={{ flexDirection: 'row', alignItems: 'center' , backgroundColor: value === 4 ? '#00362C' : 'transparent'}}
    />
</Tabs>

      </Box>
    </Box>
     <Box height={serviceHeight}  style={{ backgroundColor: '#fffff' }}>
        {subpages[value]}
      </Box>
     
  </Box>
  )
}

export default Service
