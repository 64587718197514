import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Image4 from './assets/image4.png';
const useStyles = makeStyles((theme) => ({
  parallaxContainer: {
     backgroundImage: `url(${Image4})`,
     backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed', 
    backgroundPosition: 'center',// This creates the parallax effect
    height: '400px', // Adjust the height as needed
    display: 'flex',
    width:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ffffff', // Text color for better contrast
    fontSize: '2rem', // Adjust font size as needed
    textAlign: 'center'
  },
}));

const ParallaxComponent = () => {
  const classes = useStyles();

  return (
    <Box className={classes.parallaxContainer}>
      <div>
      {/* <img src={Image4} alt="Image 4"  width={'100%'}/> */}
      </div>
    </Box>
  );
};

export default ParallaxComponent;
