import React, { useState } from 'react';
import { Box,  Typography,Button } from '@mui/material';
import DebtResolution from './assets/RatingAdvisory1.png'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';



const RatingAdvisoryPage = ({ updateServiceHeight }) => {
    const [expanded, setExpanded] = useState(false);

    const handleReadMore = () => {
      setExpanded(true);
      updateServiceHeight(1350);
    };
  
    const handleReadLess = () => {
      setExpanded(false);
      updateServiceHeight(500);
    };
    return (
  
      <Box  marginTop='10px' width={'100%'}  height={expanded ? 1450 : 400} >
      <Box height={400} display="flex" alignItems="center">
        {/* Image on the left */}
        <Box p={1} marginRight={'1%'} marginLeft={'10%'}  marginTop={5}>
          <img src={DebtResolution} alt="Debt Resolution" />
        </Box>
  
        {/* Typography on the right */}
        <Box p={1} marginTop={'-1%'} >
          <Typography variant="body1" sx={{ color: 'black',  font:'Roboto Condensed' ,width:'80%' }}>
          EFS serve asa vital intermediary connecting corporates seeking ratings with domestic and international credit rating agencies. Leveraging the EFS team's extensive experience, we add significant value by facilitating desired rating upgrades or preventing downgrades through strategic refinement. Our profound grasp of rating methodologies and key credit rating determinants empowers more informed business decisions. With a comprehensive understanding of credit issues and rating triggers, our team ensures effective risk management.
          </Typography>
          {!expanded && (
            <Button onClick={handleReadMore} color="primary" sx={{ width: '80%', height: 35,  color:'#63968D',fontWeight:'bold',   bgcolor: '#D4EAE6',  marginTop:'10%'}}>
              Read More
            </Button>
          )}
        </Box>
      </Box>
      
      {expanded && (
         <Box marginLeft={'10%'} width={'80%'}>
         <Typography variant="body1" component="div" sx={{ color: 'black',  font:'Roboto Condensed' , marginTop: '1%' ,fontWeight: 'bold' }}>
         Understanding Rating Requirements
          </Typography>
         <Typography variant="body1" component="div" sx={{ color: 'black',font:'Roboto Condensed' , marginTop: '5px' }}>
         Our process begins with a thorough comprehension of the credit rating necessities and anticipated outcomes. The company shares financials, project details, and other pertinent information for initial data analysis.
       
          </Typography>
          <ArrowDownwardIcon sx={{ color: '#87D1C8', marginLeft: '5px' }} />
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , fontWeight: 'bold', marginTop: '10px' }}>
          Quantitative Analysis
          </Typography>
         <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , marginTop: '5px' }}>
         We meticulously analyze historical and forecasted financial statements, adjusting credit ratios for agency methodologies and various scenarios. Management is apprised of critical issues and areas for enhancement.
          </Typography>
          <ArrowDownwardIcon sx={{ color: '#87D1C8', marginLeft: '5px' }} />
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , fontWeight: 'bold', marginTop: '10px' }}>
          Management Discussion
          </Typography>
         <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , marginTop: '5px' }}>
         We provide valuable insights to clients, suggesting areas for improvement and preparing management for rating agency interviews. This includes providing draft questions and key messages to highlight credit strengths and address weaknesses, supplemented by mock interviews if necessary.
          </Typography>
          <ArrowDownwardIcon sx={{ color: '#87D1C8', marginLeft: '5px' }} />
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , fontWeight: 'bold', marginTop: '10px' }}>
          Selection of Rating Agency
          </Typography>
         <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , marginTop: '5px' }}>
         We maintain a comprehensive record of sector outlooks and past ratings within specific sectors by different agencies, aiding in the selection of the most suitable rating agency for the company.
          </Typography>
          <ArrowDownwardIcon sx={{ color: '#87D1C8', marginLeft: '5px' }} />
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , fontWeight: 'bold', marginTop: '10px' }}>
          Preparation of Agency Submissions
          </Typography>
         <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , marginTop: '5px' }}>
         We assist in preparing supplementary materials for submission to the rating agency, facilitating a fair assessment of the company's creditworthiness.
          </Typography>
          <ArrowDownwardIcon sx={{ color: '#87D1C8', marginLeft: '5px' }} />
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , fontWeight: 'bold', marginTop: '10px' }}>
          Coordination with Rating Agency
          </Typography>
         <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , marginTop: '5px' }}>
         We provide support during presentations and discussions with the chosen rating agency, serving as partners to our clients by furnishing all necessary information to the agency.
          </Typography>
          <ArrowDownwardIcon sx={{ color: '#87D1C8', marginLeft: '5px' }} />
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , fontWeight: 'bold', marginTop: '10px' }}>
          Credit Ratings Finalization
          </Typography>
         <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , marginTop: '5px' }}>
         We advise clients on whether to appeal aspects of the decision or accept the outcome and transition to a public rating when appropriate
          </Typography>
          <ArrowDownwardIcon sx={{ color: '#87D1C8', marginLeft: '5px' }} />
          <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , fontWeight: 'bold', marginTop: '10px' }}>
          Monitoring and Future Planning
          </Typography>
         <Typography variant="body1" component="div" sx={{ color: 'black', font:'Roboto Condensed' , marginTop: '5px' }}>
         Recognizing that ratings are often subject to periodic review, we assist clients in devising long-term strategiesto maintain and enhance their creditworthiness.
          </Typography>
        
          <Button onClick={handleReadLess} color="primary" sx={{ width: '60%', height: 45, color:'#63968D',fontWeight:'bold', bgcolor: '#D4EAE6' ,marginLeft:'25%', marginTop:'3%' }}>
            Read Less
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default RatingAdvisoryPage