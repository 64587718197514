// Footer.js
import React from 'react';
import { Box, Container, Typography, Link, IconButton,useMediaQuery } from '@mui/material';
import EmailIcon from './assets/Email.svg';
import MobileIcon from './assets/Mobile.svg'
import LocationIcon from './assets/Vector.svg'
import linkedinIcon from './assets/linkedin.svg'
import FacebookIcon from './assets/Facebook.svg'
import twitterIcon from './assets/Twitter-2023.svg'
import Map from './assets/Map.png';
import { useLocation } from 'react-router-dom'; 

const Footer = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const location = useLocation();


  const isHomePage = location.pathname === '/';
  const isAboutPage = location.pathname === '/about';
  const isTransactionPage = location.pathname === '/transactions';
  const isServicePage = location.pathname === '/Service';
  const isContactPage = location.pathname === '/Contact';



  return (
    <footer >

      <Box bgcolor="#3A3A3A" height={379}  >
      <Container>
            <Typography variant="body1" component="div" sx={{ display: 'flex', justifyContent: 'center',color:'#FFFFFF' }}>
           <Box p={1}  width={'30%'} marginTop={6}>
           <Typography  component="div" sx={{ color: 'white' ,  font:"Roboto",fontFamily:'Bolt' ,fontSize:'30px' }}>
               Quick Links
            </Typography>
            <Typography variant="body2" component="div" sx={{ width:'60%', color: 'white',font:"Roboto",fontSize:'16px',marginTop:'5%'}} bgcolor={isHomePage ? '#00362C' : '#3A3A3A'}>
               Home
            </Typography>
            <Typography variant="body2" component="div" sx={{width:'60%', color: 'white' ,font:"Roboto",fontSize:'16px' ,marginTop:'5%'}} bgcolor={isAboutPage ? '#00362C' : '#3A3A3A'}>
             About
          
            </Typography>
            <Typography variant="body1" component="div" sx={{ width:'60%',color: 'white',font:"Roboto",fontSize:'16px',marginTop:'5%' }} bgcolor={isServicePage ? '#00362C' : '#3A3A3A'}>
            
            Services
          
            </Typography>
            <Typography variant="body1" component="div" sx={{width:'60%', color: 'white',font:"Roboto",fontSize:'16px' ,marginTop:'5%'}} bgcolor={isTransactionPage ? '#00362C' : '#3A3A3A'}>
            
               Transactions
           
            </Typography>
         
            <Typography variant="body1" component="div" sx={{width:'60%', color: 'white' ,font:"Roboto",fontSize:'16px' ,marginTop:'5%'}} bgcolor={isContactPage ? '#00362C' : '#3A3A3A'}>
            Contact
            </Typography>
            
            </Box>

          
            <Box p={1}  width={450}  marginTop={6}>
           
           
              <Typography  component="div" sx={{ color: 'white' ,font:"Roboto", fontFamily:'Bolt' ,fontSize:'30px' }}>
                 Contact Now 
                </Typography>
               
                <Typography variant="body1" component="div" sx={{ color: 'white', marginTop:'5%',fontSize:'15px',font:"Roboto"}}>
                <img src={EmailIcon} alt="Email" style={{ marginRight: '10px' }} />info@elementsfs.com
                </Typography>
                <Typography variant="body1" component="div" sx={{ color: 'white', marginTop:'5%',fontSize:'15px' }}>
                <img src={MobileIcon} alt="Email" style={{ marginRight: '10px' }} />+91 9920 820 795
                </Typography>
                <Typography variant="body1" component="div" sx={{ color: 'white', marginTop:'5%' ,fontSize:'15px' }}>
                <img src={LocationIcon} alt="Email" style={{ marginRight: '10px' }} />805,2nd Floor,Trade Link,Tower A,<br></br> Kamala Mills, Lower Parel, Mumbai 400013.
                </Typography>
                <Typography variant="body1" component="div" sx={{ color: 'white', marginTop:'5%'  }}>
                <img src={twitterIcon} alt="twitter" style={{ marginRight: '20px' }} /> <img src={FacebookIcon} alt="Facebook" style={{ marginRight: '20px' }} /> 
                
                 <a href="https://www.linkedin.com/company/elements-financial-solutions-efs/" target="_blank" rel="noopener noreferrer">
                  <img src={linkedinIcon} alt="linkedin" style={{ marginRight: '20px' }} />
                 </a>
                </Typography>
            </Box>
            <Box p={1}  width={'30%'}  marginRight={35} marginTop={5}>
            <img src={Map}  />
            </Box>
          </Typography>
      </Container>
      </Box>
       <Box bgcolor="#000" height={45} display="flex" justifyContent="space-between" alignItems="center">

      
        <Typography variant="body1" component="div" sx={{ color: 'white' , marginLeft:'43%'  }}>
          Copyright © 2024. All Rights Reserved.
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;
