import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Service from './components/Service';
import Transactions from './components/Transactions';
import ScrollToTop from './components/ScrollToTop';
import Contact from './components/Contact';

import { GlobalStyles } from './components/GlobalStyles';

function App() {
  return (
    <Router>
      <div>
        <ScrollToTop/>
        <Header />
        <div style={{ marginTop: '120px' }}>
          <Routes>
            <Route path="/" exact element={<Home  />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route path="/transactions" element={<Transactions />} />
            
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
